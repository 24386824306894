@import 'mixins';

@font-face {
    font-family: Bliss Pro;
    src: url('/assets/fonts/BlissPro.ttf');
    font-display: swap;
}

@font-face {
    font-family: Jaguar Cyrillic;
    src: url('/assets/fonts/JaguarCyrillic.ttf');
    font-display: swap;
}

* {
    font-family: Bliss Pro, sans-serif;
    box-sizing: border-box;
    line-height: 22px;
}

a {
    text-decoration: none;
    display: inline-flex;
    color: #75adbc;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.image-responsive {
    display: block;
    width: 100%;
    height: auto;
}

.link {
    color: black;
}

.breadcrumbs {
    list-style-type: none;
    margin: 0 0 10px;
    padding: 0;
    font-size: 14px;

    @include breakpoint('sm') {
        margin-bottom: 20px;
    }

    &__item {
        display: none;

        @include breakpoint('sm') {
            display: inline-block;

            & + &::before {
                content: '/';
                padding: 0 4px 0 2px;
            }
        }

        &_active {
            color: #a0a0a0;
        }

        &_prev {
            display: inline-block;

            .link::before {
                vertical-align: top;
                content: url(/assets/icons/arrow-left.svg);

                @include breakpoint('sm') {
                    display: none;
                }
            }
        }
    }
}

.sheet {
    background-color: #f4f5f4;

    .input input,
    .input textarea,
    .select__label {
        background-color: white;
    }
}

.h2 {
    font-size: 25px;
    color: #005a70;
    line-height: 30px;

    @include breakpoint('sm') {
        font-size: 45px;
        line-height: 54px;
    }
}

.h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;

    @include breakpoint('sm') {
        font-size: 25px;
        line-height: 36px;
    }
}

.h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    @include breakpoint('sm') {
        font-size: 20px;
        line-height: 24px;
    }
}

.text-warning {
    color: #ffa900;
}

.text-success {
    color: #01a046;
}

.text-grey {
    color: #949c9e;
}

.text-danger {
    color: #eb154c;
}

.slider {
    position: relative;

    &__slides {
        .slider__slide {
            height: auto;
            display: flex;
        }
    }

    &__arrow {
        background-color: white;
        position: absolute;
        top: calc(50% - 30px);
        padding: 10px;
        box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
        border-radius: 30px;
        cursor: pointer;
        border: none;
        z-index: 1;

        &:hover {
            box-shadow: 0px 8px 30px 10px rgba(108, 108, 108, 0.15);
        }

        &_prev {
            left: 10px;
        }

        &_next {
            right: 10px;
        }

        &_disabled {
            visibility: hidden;
        }

        .icon {
            display: block;
            width: 20px;
            height: 20px;
            --stroke-color: black;
            fill: none;
        }
    }

    &__dots {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        bottom: 10px;
        z-index: 1;
    }

    &__dot {
        width: 10px;
        height: 10px;
        border: 1px solid #388ea0;
        border-radius: 30px;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 16px;
        }

        &:hover,
        &_active {
            background-color: #388ea0;
        }
    }
}

.product-list {
    display: flex;
    margin: -5px;

    @include breakpoint('xl') {
        margin: -10px;
    }

    &__item {
        flex: 0 0 calc(100% - 10px);
        margin: 5px;

        @include breakpoint('md') {
            flex: 0 0 calc(100% / 2 - 10px);
        }

        @include breakpoint('xl') {
            flex: 0 0 calc(100% / 2 - 20px);
            margin: 10px;
        }
    }

    &__item-inner {
        padding: 16px;
        border-radius: 5px;
        box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
        display: flex;

        @include breakpoint('sm') {
            padding: 20px;
        }

        @include breakpoint('md') {
            box-shadow: none;

            &:hover {
                box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
            }
        }

        @include breakpoint('lg') {
            padding: 20px 30px;
        }

        .image {
            flex: 0 0 calc(100% * 5 / 12 - 16px);
            min-width: 110px;
            margin-right: 16px;

            img {
                min-height: 100px;
                max-height: 150px;
                object-fit: contain;

                @include breakpoint('sm') {
                    max-height: 200px;
                }

                @include breakpoint('md') {
                    max-height: 120px;
                }

                @include breakpoint('lg') {
                    max-height: 140px;
                }
            }

            .badge {
                padding: 0 8px;
                background-color: #b3d1d9;
                color: white;
                border-radius: 5px;
                margin-bottom: 10px;
                display: none;

                @include breakpoint('md') {
                    display: inline-block;
                }
            }

            .icons {
                display: flex;
                margin-top: 10px;

                .icon {
                    width: 20px;
                    height: 20px;
                    fill: none;
                    --fill-color: #ffa900;

                    &:not(:last-child) {
                        margin-right: 14px;

                        @include breakpoint('md') {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        .body {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            word-break: break-word;

            @include breakpoint('md') {
                align-self: center;
            }

            .title {
                display: block;
                margin-bottom: 2px;
                font-weight: bold;

                @include breakpoint('sm') {
                    margin-bottom: 10px;
                }
            }

            .description {
                display: block;
                font-size: 14px;
                line-height: 18px;
                color: #4e4e4e;
                margin-bottom: 8px;
                @include line-clamp(3);

                @include breakpoint('sm') {
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 16px;
                }
            }

            .add-to-cart {
                align-self: flex-start;

                .button {
                    display: none;

                    @include breakpoint('md') {
                        display: flex;
                    }
                }

                .button_text {
                    display: flex;

                    @include breakpoint('md') {
                        display: none;
                    }

                    .icon {
                        width: 25px;
                        height: 25px;
                        fill: none;
                        --fill-color: #eb154c;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}

.dropdown {
    position: relative;

    &__content {
        position: absolute;
        display: none;
    }

    &_active > &__content {
        display: block;
    }
}

.form {
    display: flex;
    flex-direction: column;

    &__title {
        margin-bottom: 20px;
    }

    &__inputs {
        margin: -3px -14px 3px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .select,
        .input {
            flex: 0 0 calc(100% - 28px);
            margin: 3px 14px;
        }
    }

    &_registration,
    &_contact-us,
    &_offer {
        @include breakpoint('sm') {
            .form__inputs {
                .select,
                .input {
                    flex: 1 1 calc(100% / 2 - 28px);
                }

                .select {
                    margin-bottom: 25px;
                }
            }

            .form__submit {
                min-width: 300px;
                align-self: center;
            }
        }
    }

    &_contact-us {
        .form__inputs {
            .input-phone {
                flex: 1 1 100%;
            }
        }
    }

    &_offer {
        .form__title {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &_login {
        .form__submit {
            margin-bottom: 10px;
        }

        .form__auth-providers {
            margin-top: 60px;
            display: flex;
            flex-direction: column;

            .title {
                margin-bottom: 10px;
            }

            .button {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                .icon {
                    margin-right: 6px;
                }
            }
        }

        .form__link {
            align-self: flex-start;
            color: #75adbc;
            font-weight: normal;
        }
    }

    &_save-changes {
        margin-top: 10px;

        .form__actions {
            display: flex;
            flex-direction: column;

            .button:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

.button {
    min-width: 200px;
    padding: 8px 30px;
    border-radius: 5px;
    background-color: white;
    font-weight: bold;
    color: black;
    border: 1px solid #ededed;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:disabled) {
        cursor: pointer;
    }

    &:hover {
        border-color: #75adbc;
        box-shadow: 0 0 0 1px #75adbc;
    }

    &:focus {
        outline: none;
        border-color: #75adbc;
    }

    &:active {
        border-color: #67929d;
        box-shadow: 0 0 0 1px #67929d;
    }

    &:disabled,
    &[disabled] {
        pointer-events: none;
        color: #c2c5c7;
    }

    &_primary {
        background-color: #eb154c;
        color: white;
        border: none;

        &:hover {
            box-shadow: none;
            background-color: #f11a51;
        }

        &:focus {
            box-shadow: 0 0 0 2px #ff9db6;
            background-color: #f11a51;
        }

        &:active {
            box-shadow: none;
            background-color: #c51341;
        }

        &:disabled,
        &[disabled] {
            color: white;
            background-color: #c5c5c5;
        }
    }

    &_secondary {
        background-color: #75adbc;
        color: white;
        border: none;

        &:hover {
            box-shadow: none;
            background-color: #6f9daa;
        }

        &:focus {
            box-shadow: 0 0 0 2px rgba(95, 59, 59, 0.12);
            background-color: #6f9daa;
        }

        &:active {
            background-color: #608f9c;
        }

        &:disabled,
        &[disabled] {
            color: white;
            background-color: #c5c5c5;
        }
    }

    &_icon,
    &_text {
        min-width: 0;
        padding: 0;
        border: none;
        background-color: transparent;

        &:hover {
            box-shadow: none;
        }

        &:active {
            box-shadow: none;
        }
    }

    &_icon {
        position: relative;
        border-radius: 30px;

        .badge {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(40%, -40%);
        }
    }

    &_text {
        border-radius: 0;
    }

    &_tile {
        border-radius: 0;
        padding: 10px 20px;
    }

    &_rounded {
        border-radius: 50px;
    }
}

.input,
.select {
    display: inline-block;
    min-width: 220px;
}

.select {
    position: relative;
    user-select: none;

    &__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px;
        border-radius: 5px;
        background-color: #f4f5f4;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &::after {
            content: url(/assets/icons/arrow-down.svg);
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-left: 16px;
        }
    }

    &__options-outer {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 1;
        padding-top: 4px;
    }

    &__options-inner {
        margin: 0;
        padding: 0;
        list-style: none;
        max-height: 200px;
        overflow: auto;
        background-color: white;
        box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
        border-radius: 5px;
    }

    &__option {
        padding: 10px 16px;
        cursor: pointer;
    }

    &:focus {
        outline: none;

        .select__label {
            box-shadow: 0 0 0 1px #75adbc;
        }
    }

    &:not(:disabled):hover {
        .select__label {
            box-shadow: 0 0 0 2px #75adbc;
        }
    }

    &_active {
        .select__label::after {
            transform: rotate(180deg);
            filter: invert(71%) sepia(19%) saturate(639%) hue-rotate(146deg)
                brightness(89%) contrast(88%);
        }

        .select__options-outer {
            display: block;
        }
    }
}

.input {
    &__wrap {
        position: relative;
    }

    input,
    textarea {
        display: block;
        width: 100%;
        border: none;
        border-radius: 5px;
        padding: 10px 16px;
        background-color: #f4f5f4;
        line-height: 22px;

        &:focus {
            outline: none;
            box-shadow: 0 0 0 1px #75adbc;
        }

        &:not(:disabled):hover {
            box-shadow: 0 0 0 2px #75adbc;
        }

        &:disabled {
            color: #afafaf;
        }

        &[type='number'] {
            min-width: 0;
            width: 60px;
            text-align: center;
            -moz-appearance: textfield;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &__details {
        margin-top: 6px;
        font-size: 12px;
        min-height: 16px;

        textarea + & {
            margin-top: 0;
        }

        .hint,
        .error-message {
            line-height: 16px;
        }

        .hint {
            color: #a0a0a0;
        }

        .error-message {
            display: none;
            color: #ff0000;
        }
    }

    &_error {
        input,
        textarea {
            box-shadow: 0 0 0 1px #ff0000;

            &:focus {
                box-shadow: 0 0 0 1px #ff0000;
            }

            &:not(:disabled):hover {
                box-shadow: 0 0 0 1px #ff0000;
            }
        }

        .input__details {
            .hint {
                display: none;
            }

            .error-message {
                display: block;
            }
        }
    }

    &_rounded {
        input {
            border-radius: 50px;
        }
    }

    &_outlined {
        input {
            background-color: white;
            box-shadow: 0 0 0 1px #949c9e;
        }
    }

    &_prepend-icon {
        input {
            padding-left: 36px;
        }

        .input__icon {
            left: 12px;
        }
    }

    &_append-icon {
        input {
            padding-right: 36px;
        }

        .input__icon {
            right: 12px;
        }
    }

    &_icon-surrounded {
        input {
            padding: 10px 36px;
        }

        .input__icon {
            &_prepend {
                left: 12px;
            }

            &_append {
                right: 12px;
            }
        }
    }

    &_search {
        position: relative;

        input {
            padding-right: 16px;

            @include breakpoint('sm') {
                padding-right: 40px;
            }
        }

        .input__clear {
            display: none;

            @include breakpoint('sm') {
                right: 50px;
            }
        }

        .input__suggestions {
            display: none;
            position: absolute;
            top: calc(100% + 10px);
            z-index: 1;
            width: 100%;
            background-color: white;
            box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
            border-radius: 0px 0px 5px 5px;
        }

        .input__suggestion {
            &-inner {
                padding: 10px;
                display: flex;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #f4f5f4;
            }

            .image {
                flex: 0 0 40px;
                margin-right: 10px;
            }

            .info {
                flex: 0 0 calc(100% - 50px);
                display: flex;
                flex-direction: column;
            }

            .title {
                margin-bottom: 4px;
                color: black;
                text-transform: capitalize;
            }

            .description {
                @include line-clamp(2);
                color: #949c9e;
            }
        }

        &:focus-within {
            input {
                padding-right: 46px;

                @include breakpoint('sm') {
                    padding-right: 80px;
                }

                @include breakpoint('md') {
                    padding-right: 40px;
                }
            }

            .input__suggestions {
                display: block;
            }

            .input__clear {
                display: flex;

                @include breakpoint('md') {
                    display: none;
                }
            }
        }
    }

    &_password {
        .input__toggle-password {
            .icon {
                width: 20px;
                height: 20px;
                background-image: url(/assets/icons/eye-closed.svg);
            }

            &_active {
                .icon {
                    background-image: url(/assets/icons/eye-opened.svg);
                    filter: invert(71%) sepia(19%) saturate(639%)
                        hue-rotate(146deg) brightness(89%) contrast(88%);
                }
            }
        }
    }
}

.small-text {
    font-size: 12px;
    color: #a0a0a0;
}

.modal-backdrop {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 4;

    .modal {
        margin: 10px;
        background-color: white;
        border-radius: 4px;

        @include breakpoint('sm') {
            margin: 30px auto;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 25px;
            font-weight: 500;
            color: #005a70;
            padding: 20px;
            border-bottom: 1px solid #e5e5e5;
        }

        &__body {
            padding: 20px;
        }

        &_small {
            @include breakpoint('sm') {
                max-width: 340px;
            }
        }

        &_large {
            @include breakpoint('sm') {
                margin: 30px;
            }

            @include breakpoint('md') {
                margin: 30px auto;
                max-width: 800px;
            }
        }

        &_recovery {
            .message {
                display: block;
                margin-bottom: 20px;
            }
        }
    }
}

.content {
    position: relative;
    padding: 16px 0 60px 0;
    flex-grow: 1;
    z-index: 1;

    @include breakpoint('sm') {
        padding-top: 30px;
    }

    @include breakpoint('md') {
        padding-bottom: 100px;
    }
}

.container {
    padding: 0 20px;

    @include breakpoint('sm') {
        padding: 0 80px;
    }

    @include breakpoint('md') {
        padding: 0 100px;
    }

    @include breakpoint('lg') {
        padding: 0 140px;
    }

    @include breakpoint('xl') {
        padding: 0 310px;
    }
}

.card {
    background-color: white;
    padding: 20px;
    box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
    border-radius: 5px;

    @include breakpoint('sm') {
        padding: 20px 40px;
    }

    &_tile {
        border-radius: 0;
    }

    &__header {
        color: black;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.03em;
    }

    &__body {
        color: #6c787b;
    }

    &__header + &__body {
        margin-top: 20px;
    }
}

.load-button {
    color: #005a70;
    font-weight: normal;

    .icon {
        margin-right: 10px;
    }
}

label {
    display: block;

    span {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 16px;
        color: #6c787b;
    }
}

.checkbox {
    user-select: none;

    &__label {
        cursor: pointer;
        display: flex;
        align-items: center;

        &::before {
            content: url(/assets/icons/check.svg);
            display: inline-block;
            width: 16px;
            height: 16px;
            padding: 2px;
            margin-right: 8px;
            border: 1px solid #75adbc;
            border-radius: 2px;
        }
    }

    &__input {
        display: none;
    }

    &__input:checked + &__label::before {
        background-color: #75adbc;
    }
}

.table {
    width: 100%;
    font-size: 12px;
    border-spacing: 0;
    line-height: 16px;

    @include breakpoint('sm') {
        font-size: 16px;
        line-height: 22px;
    }

    &__head {
        border-radius: 5px 5px 0 0;

        .table__row {
            border-radius: inherit;
        }
    }

    &__body {
        background-color: white;

        .table__row:last-child {
            .table__cell {
                border-bottom: 1px solid #e0e0e0;
            }
        }
    }

    &__cell {
        padding: 10px 16px;
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;

        @include breakpoint('sm') {
            padding: 20px 16px;
        }

        &:last-child {
            border-right: 1px solid #e0e0e0;
        }

        &_header {
            border: none;
            border-left: 1px solid #e0e0e0;
            text-align: start;
            background-color: #005a70;
            color: white;
            font-weight: bold;

            &:first-child {
                border-left: none;
                border-top-left-radius: inherit;
            }

            &:last-child {
                border-right: none;
                border-top-right-radius: inherit;
            }
        }
    }
}

.news-or-articles {
    &:not(:last-child) {
        margin-bottom: 60px;
    }

    &__heading {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 14px;

        @include breakpoint('sm') {
            font-size: 30px;
            font-weight: 400;
            line-height: 36px;
            margin-bottom: 18px;
        }

        .curve {
            flex-grow: 1;
            display: none;
            margin-left: 20px;

            @include breakpoint('sm') {
                display: block;
            }
        }
    }

    .news-or-articles__slider {
        margin: -30px -20px;

        @include breakpoint('sm') {
            margin: -30px -80px;
        }

        @include breakpoint('md') {
            margin: -30px -100px;
            padding: 0 84px;
        }

        @include breakpoint('lg') {
            display: none;
        }

        .swiper-container {
            padding: 30px 20px;

            @include breakpoint('sm') {
                padding: 30px 80px;
            }

            @include breakpoint('md') {
                padding: 30px 16px;
            }
        }

        .slider__slide {
            max-width: 250px;

            @include breakpoint('md') {
                max-width: none;
            }
        }

        .list__item {
            width: 100%;
        }

        .slider__arrow {
            display: none;

            @include breakpoint('md') {
                display: block;
            }
        }
    }

    &__content {
        display: none;
        flex-direction: column;

        @include breakpoint('lg') {
            display: flex;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            margin: -8px;

            @include breakpoint('xl') {
                margin: -10px;
            }
        }

        .list__item {
            flex: 0 0 calc(100% / 4 - 16px);
            margin: 8px;

            @include breakpoint('xl') {
                flex: 0 0 calc(100% / 4 - 20px);
                margin: 10px;
            }
        }
    }

    .list__item-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
        border-radius: 10px;

        @include breakpoint('lg') {
            box-shadow: 0 0 0 1px #e7e7e7;

            &:hover {
                box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
            }
        }

        .image {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;

            img {
                border-radius: inherit;
            }
        }

        .title,
        .subtitle,
        .description,
        .actions {
            padding: 14px 14px 0;
            margin-bottom: 14px;

            @include breakpoint('xl') {
                padding: 20px 20px 0;
                margin-bottom: 20px;
            }
        }

        .title {
            font-weight: 700;

            @include breakpoint('sm') {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
            }

            & + .subtitle,
            & + .description,
            & + .actions {
                margin-top: -4px;
                padding-top: 0;
            }
        }

        .subtitle {
            font-size: 12px;
            line-height: 15px;
            color: #949c9e;

            & + .description,
            & + .actions {
                margin-top: -4px;
                padding-top: 0;
            }
        }

        .description {
            color: #6c787b;
            font-size: 14px;
            line-height: 20px;
            @include line-clamp(5);

            @include breakpoint('sm') {
                font-size: 16px;
                line-height: 22px;
            }

            & + .actions {
                padding-top: 0;
            }
        }

        .actions {
            margin-top: auto;
            display: flex;

            .button {
                flex-grow: 1;
            }
        }
    }

    .load-button {
        align-self: center;
        margin-top: 40px;
    }
}

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.top-panel {
    background-color: #005a70;
    color: white;
}

.header {
    display: none;
    position: relative;
    flex-direction: column;
    box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
    background-color: white;
    z-index: 3;

    @include breakpoint('sm') {
        display: flex;
    }

    &_mobile {
        position: relative;
        display: flex;
        padding: 10px 20px;
        z-index: 3;
        box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
        background-color: white;

        @include breakpoint('sm') {
            display: none;
        }

        .menu-button {
            flex-shrink: 0;

            &__icon {
                width: 40px;
                height: 40px;
                --fill-color: #2c4143;
            }

            &_active {
                .menu-button__icon {
                    --fill-color: #75adbc;
                }
            }
        }

        .logo {
            margin: 0 auto 0 calc(100% / 2 - 87px);
            min-width: 94px;
            width: 94px;

            img {
                height: 40px;
            }
        }

        .actions {
            display: flex;

            .button {
                width: 40px;
                height: 40px;

                &:not(:last-child) {
                    margin-right: 4px;
                }

                .icon {
                    --fill-color: #2c4143;

                    &_search {
                        width: 24px;
                        height: 24px;
                    }

                    &_cart {
                        width: 36px;
                        height: 36px;
                    }
                }
            }

            .search-open_active {
                .icon {
                    --fill-color: #75adbc;
                }
            }
        }

        .search {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            padding: 10px 20px;
            display: none;
            flex-direction: column;
            background-color: white;

            &_active {
                display: flex;
            }
        }
    }

    .top-panel {
        .content-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .menu-button {
                &__icon {
                    width: 40px;
                    height: 40px;
                    --fill-color: white;

                    &_close {
                        display: none;
                    }
                }

                &_active {
                    .menu-button__icon {
                        &_menu {
                            display: none;
                        }

                        &_close {
                            display: block;
                        }
                    }
                }

                @include breakpoint('md') {
                    display: none;
                }
            }
        }
    }

    .bottom-panel {
        .content-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &__nav {
        flex: 0 0 100%;
        margin: -4px 0;
        padding-bottom: 20px;
        justify-content: space-between;
        flex-wrap: wrap;
        display: none;

        @include breakpoint('md') {
            display: flex;
            flex: 0 0 auto;
            margin-left: auto;
            padding: 0;
        }

        &_visible {
            display: flex;
        }

        .nav-link {
            min-height: 26px;
            margin: 4px 0;
            align-items: center;
            color: white;

            &:not(:last-child) {
                margin-right: 20px;

                @include breakpoint('lg') {
                    margin-right: 40px;
                }
            }

            &_active {
                align-items: flex-end;
                border-bottom: 2px solid #eb154c;
            }
        }
    }

    &__extension {
        padding: 20px 0;
        display: flex;
        margin-left: auto;

        @include breakpoint('md') {
            order: 3;
        }
    }

    &__lang-switcher {
        position: relative;
        margin: 0 20px 0 auto;
        user-select: none;
        cursor: pointer;

        @include breakpoint('md') {
            margin-left: 0;
        }

        .current-locale {
            display: flex;
            align-items: center;
            text-transform: uppercase;

            &:focus {
                outline: none;
            }

            .icon {
                width: 20px;
                height: 20px;
                fill: none;
                --stroke-color: white;
            }
        }

        .locales {
            display: none;
            position: absolute;
            top: calc(100% + 4px);
            z-index: 1;
            width: 100%;
            background: #ffffff;
            box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
            border-radius: 5px;
            color: black;

            &__item {
                a {
                    padding: 4px 10px;
                }
            }
        }

        &:focus-within {
            .current-locale {
                .icon {
                    transform: rotate(180deg);
                }
            }

            .locales {
                display: block;
            }
        }
    }

    &__socials {
        display: flex;

        a {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-right: 20px;
            }

            .icon {
                width: 20px;
                height: 20px;
                --fill-color: white;
            }
        }
    }

    &__logo {
        width: 190px;
        margin: 15px 0;

        @include breakpoint('lg') {
            width: 240px;
            margin: 20px 0 20px -40px;
        }
    }

    &__search {
        flex: 0 0 100%;
        min-height: 66px;
        padding-bottom: 20px;
        display: flex;

        @include breakpoint('lg') {
            flex: 1 0 auto;
            min-height: 0;
            padding: 0;
            margin: 0 3% 0;
        }

        @include breakpoint('xl') {
            margin: 0 56px 0 80px;
        }

        .catalog-menu {
            margin-right: 10px;

            @include breakpoint('sm') {
                flex: 1 0 200px;
                max-width: 220px;
            }

            @include breakpoint('md') {
                max-width: 240px;
            }
        }

        .main-search {
            flex: 2 0 auto;

            @include breakpoint('lg') {
                flex-basis: 280px;
            }

            .input,
            .input__wrap,
            input {
                height: 100%;
            }
        }

        &_active {
            .catalog-menu {
                display: none;

                @include breakpoint('md') {
                    display: block;
                }
            }
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        margin: 0 10px 0 auto;

        @include breakpoint('lg') {
            order: 3;
        }
    }

    &__login {
        display: flex;

        @include breakpoint('lg') {
            order: 4;
        }

        li:not(:last-child) {
            margin-right: 8px;
        }

        .profile-menu {
            position: relative;

            &:focus-within {
                .actions {
                    display: block;
                }
            }

            .menu-button {
                color: #005a70;
                font-weight: 400;

                .icon {
                    width: 30px;
                    height: 30px;
                    margin-right: 6px;
                    --fill-color: #2c4143;
                }

                .text {
                    max-width: 156px;
                }
            }

            .actions {
                display: none;
                position: absolute;
                top: calc(100% + 10px);
                right: -30px;
                z-index: 1;
                box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
                background-color: white;
                border-radius: 5px;

                &:focus {
                    outline: none;
                }

                .email {
                    padding: 16px;
                    color: #949c9e;
                }

                .links {
                    padding: 10px 16px;
                    border-top: 1px solid #ededed;
                    border-bottom: 1px solid #ededed;

                    &__item:not(:last-child) {
                        margin-bottom: 16px;
                    }

                    .button {
                        display: inline-flex;

                        .icon {
                            width: 20px;
                            height: 20px;
                            margin-right: 4px;
                            --fill-color: #75adbc;
                        }
                    }
                }

                .logout {
                    padding: 10px 16px;
                }
            }
        }

        .item {
            position: relative;
            cursor: pointer;
            font-size: 20px;
            line-height: 24px;
        }

        .item + .item::before {
            content: '';
            cursor: default;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -4px;
            border-right: 1px solid #949c9e;
            color: #949c9e;
        }
    }
}

.nav-mobile {
    position: absolute;
    overflow-y: auto;
    top: 60px;
    width: 280px;
    height: 100%;
    max-height: calc(100vh - 60px);
    background-color: white;
    box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
    transform: translateX(-280px);
    z-index: 2;

    @include breakpoint('sm') {
        display: none;
    }

    &_visible {
        transform: translateX(0);
    }

    &__login {
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px;

        .item {
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        .info {
            margin-bottom: 10px;

            .username {
                display: block;
                color: #005a70;
            }

            .email {
                color: #949c9e;
            }
        }

        .button {
            width: 100%;
            margin: 4px 0 10px;
            justify-content: flex-start;
            font-weight: 400;

            .icon-login {
                width: 30px;
                height: 30px;
                margin-right: 4px;
                --fill-color: #2c4143;
            }

            .icon-arrow {
                width: 20px;
                height: 20px;
                margin-left: auto;
                fill: none;
                --stroke-color: black;
            }
        }

        .logout {
            width: 100%;
            padding: 10px 0 0;
            border-top: 1px solid #e5e5e5;

            button {
                margin: 0;
                padding: 0;
                background-color: transparent;
                border: none;
                cursor: pointer;
                color: #eb154c;
            }
        }
    }

    &__links {
        border-bottom: 1px solid #e5e5e5;
        padding: 16px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .nav-link {
            color: #6c787b;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }

    &__lang-switcher {
        border-bottom: 1px solid #e5e5e5;
        padding: 16px 20px;
        display: flex;
        align-items: center;
        font-weight: bold;

        .variant {
            color: black;
            padding: 6px 16px;
            border-radius: 20px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &[selected] {
                border: 1px solid #75adbc;
            }
        }
    }

    &__socials {
        padding: 20px;
        display: flex;
        align-items: center;

        a:not(:last-child) {
            margin-right: 20px;
        }

        .icon {
            width: 30px;
            height: 30px;
            --fill-color: #388ea0;
        }
    }
}

.footer {
    display: flex;
    flex-direction: column;
    z-index: 1;

    .top-panel {
        .content-wrapper {
            display: grid;
            gap: 40px;
            padding: 50px 0 30px;

            @include breakpoint('sm') {
                column-gap: 20px;
                grid-template-columns: minmax(216px, 1fr) 1fr;
                justify-items: start;
            }

            @include breakpoint('md') {
                grid-template-columns: repeat(3, auto);
            }
        }
    }

    .bottom-panel {
        .content-wrapper {
            display: grid;
            padding: 30px 0 10px 0;

            @include breakpoint('sm') {
                grid-template-rows: repeat(2, auto);
                grid-template-columns: repeat(2, auto);
                padding: 0;
            }

            @include breakpoint('lg') {
                grid-template-rows: auto;
                grid-template-columns: repeat(3, auto);
            }
        }
    }

    &__address,
    &__schedule,
    &__contacts {
        display: flex;
        flex-direction: column;

        .addresses,
        .work-days,
        .phones,
        .emails {
            &__item {
                display: flex;

                &::before {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                }

                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }
        }

        .days,
        .phones {
            min-width: 126px;
            margin-right: 16px;

            @include breakpoint('sm') {
                min-width: 0;
            }
        }

        .title {
            margin-bottom: 20px;
            font-size: 26px;
            font-weight: 500;
        }
    }

    &__address {
        .addresses {
            &__item {
                align-items: center;

                &::before {
                    content: url(/assets/icons/location.svg);
                    margin-right: 4px;
                }
            }
        }
    }

    &__schedule {
        @include breakpoint('sm') {
            grid-column: 1 / 2;
        }
        @include breakpoint('md') {
            grid-column: 2 / 3;
            justify-self: center;
        }

        .work-days {
            &__item {
                .work-time {
                    @include breakpoint('sm') {
                        margin-left: auto;
                    }
                }
            }
        }
    }

    &__contacts {
        @include breakpoint('sm') {
            justify-self: stretch;
            grid-row: 1 / 3;
            grid-column: 2 / 3;
        }

        @include breakpoint('md') {
            grid-row: 1 / 2;
            grid-column: 3 / 4;
            justify-self: end;
        }

        .info {
            display: flex;
            flex-wrap: wrap;

            .phones,
            .emails {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                @include breakpoint('sm') {
                    align-items: flex-end;
                }

                &__item {
                    &:first-child {
                        flex-direction: column;

                        @include breakpoint('sm') {
                            flex-direction: row;
                            align-items: center;
                        }

                        &::before {
                            margin-bottom: 4px;

                            @include breakpoint('sm') {
                                margin: 0 4px 0 0;
                            }
                        }
                    }
                }
            }

            .phones {
                &__item {
                    &:first-child {
                        &::before {
                            content: url(/assets/icons/phone.svg);
                        }
                    }
                }
            }

            .emails {
                &__item {
                    &:first-child {
                        &::before {
                            content: url(/assets/icons/mail.svg);
                        }
                    }
                }
            }
        }
    }

    &__logo {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        order: 1;

        @include breakpoint('sm') {
            padding: 0;
            grid-row: 1 / 3;
            align-self: center;
            order: -1;
        }

        @include breakpoint('lg') {
            margin: 12px 0;
            grid-row: 1 / 2;
        }

        img {
            width: 130px;
            margin-bottom: 10px;
            height: auto;
        }
    }

    &__nav {
        margin: -10px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;

        @include breakpoint('sm') {
            flex-direction: row;
            justify-content: flex-end;
            margin: 15px -10px -5px;
        }

        @include breakpoint('lg') {
            justify-content: center;
            align-items: center;
            margin: -5px -20px;
        }

        .link {
            margin: 10px 0;

            @include breakpoint('sm') {
                margin: 5px 10px;
            }

            @include breakpoint('lg') {
                margin: 5px 20px;
            }
        }
    }

    &__socials {
        display: flex;
        align-items: center;
        margin: 30px 0 15px;

        @include breakpoint('sm') {
            margin: 0;
            padding: 20px 0;
            justify-content: flex-end;
        }

        a:not(:last-child) {
            margin-right: 20px;
        }

        .icon {
            width: 30px;
            height: 30px;
            --fill-color: #388ea0;

            @include breakpoint('md') {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.cart {
    &__list {
        margin: -20px -20px 30px -20px;
    }

    &__list-item {
        font-size: 16px;
        padding: 20px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: flex-start;

        @include breakpoint('sm') {
            font-size: 20px;
            align-items: center;
        }

        .content-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;

            @include breakpoint('sm') {
                flex-direction: row;
                align-items: center;
            }
        }

        .info {
            display: flex;
            align-items: center;
            margin-right: auto;

            .photo {
                width: 80px;
                margin-right: 6px;

                @include breakpoint('sm') {
                    margin-right: 10px;
                }
            }
        }

        .counter {
            display: flex;
            margin-top: 20px;

            @include breakpoint('sm') {
                margin: 0 0 0 10px;
            }

            .input {
                min-width: 0;
                margin: 0 6px;
            }
        }

        .trash-bin {
            margin: 26px 0 0 10px;

            @include breakpoint('sm') {
                margin-top: 0;
            }
        }
    }

    &_empty {
        display: flex;
        flex-direction: column;

        .alert {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
            padding: 20px 0 40px;
            border-bottom: 1px solid #f4f5f4;

            @include breakpoint('sm') {
                margin-bottom: 40px;
            }

            &__image {
                margin-bottom: 40px;
            }

            &__text {
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        .button {
            @include breakpoint('sm') {
                min-width: 280px;
                align-self: center;
            }
        }
    }
}

.catalog-menu {
    position: relative;
    user-select: none;

    &__button {
        width: 100%;
        display: none;
        justify-content: flex-start;
        font-size: 20px;
        font-weight: normal;

        @include breakpoint('sm') {
            justify-content: center;
        }

        @include breakpoint('md') {
            display: flex;
        }

        .icon {
            fill: none;
            --stroke-color: white;
        }

        .icon_list {
            width: 30px;
            height: 30px;
            margin-right: 6px;
        }

        .icon_arrow {
            width: 20px;
            height: 20px;
            margin-left: auto;
        }

        &_mobile {
            display: flex;

            @include breakpoint('md') {
                display: none;
            }
        }
    }

    &__back {
        width: 100%;
        padding: 14px 20px;
        border-bottom: 1px solid #e5e5e5;
        justify-content: flex-start;
        color: #75adbc;
        font-size: 20px;
        font-weight: normal;

        &:hover,
        &:focus {
            border-color: #e5e5e5;
        }

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            fill: none;
            --stroke-color: #75adbc;
        }

        &_to-menu {
            @include breakpoint('sm') {
                display: none;
            }
        }
    }

    &__list-wrapper,
    .categories {
        position: absolute;
        min-width: 240px;
        width: 100%;
        height: calc(100vh - 60px);
        display: none;

        @include breakpoint('sm') {
            height: auto;
        }
    }

    &__list-wrapper {
        top: 0;

        @include breakpoint('sm') {
            top: 100%;
            padding-top: 10px;
        }
    }

    &__list {
        position: relative;
        height: 100%;
        padding: 0;
    }

    &__item_active {
        .categories {
            display: block;

            @include breakpoint('md') {
                display: block;
            }
        }
    }

    &__item:hover {
        .categories {
            @include breakpoint('md') {
                display: block;
            }
        }
    }

    &__item-inner {
        width: 100%;
        height: 60px;
        padding: 8px 16px;
        background-color: white;
        border: none;
        display: none;
        justify-content: flex-start;
        align-items: center;

        @include breakpoint('md') {
            display: flex;
        }

        .icon {
            flex: 0 0 20px;
            height: 20px;

            &_placeholder {
                margin-right: 10px;
                --fill-color: #75adbc;
            }

            &_arrow {
                margin-left: auto;
                fill: none;
                --stroke-color: black;
            }
        }

        .title {
            text-align: start;
        }

        &:hover,
        &:focus {
            outline: none;
            background-color: #75adbc;
            color: white;

            .icon_placeholder {
                --fill-color: white;
            }

            .icon_arrow {
                --stroke-color: white;
            }
        }

        &_mobile {
            display: flex;

            @include breakpoint('md') {
                display: none;
            }
        }
    }

    .categories {
        top: 0;
        height: 100%;

        @include breakpoint('md') {
            left: 100%;
            width: calc(100vw - 456px);
            padding-left: 10px;
        }

        @include breakpoint('lg') {
            width: calc(100vw - 770px);
        }

        @include breakpoint('xl') {
            width: calc(100vw - 1158px);
        }

        &__expansions,
        &__list {
            height: 100%;
            overflow: auto;
        }

        &__expansions {
            padding: 0;

            @include breakpoint('md') {
                display: none;
            }
        }

        &__list {
            padding: 20px 40px;
            display: none;

            @include breakpoint('md') {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
                align-content: start;
                gap: 40px;
            }
        }

        &__item {
            flex: 1 1 auto;
            padding: 14px 20px;
            border-bottom: 1px solid #e5e5e5;

            @include breakpoint('md') {
                padding: 0;
                border: none;
            }
        }
    }

    .subcategories {
        &__list {
            margin-bottom: 16px;

            @include breakpoint('md') {
                margin: 0;
            }
        }

        &__item {
            &:not(:last-child) {
                margin-bottom: 16px;
            }

            a {
                color: #6c787b;
            }
        }

        &__all {
            color: black;
            text-decoration: underline;
        }
    }

    &_active {
        .catalog-menu__list-wrapper {
            display: block;

            @include breakpoint('md') {
                display: none;
            }
        }
    }

    &:hover {
        .catalog-menu__list-wrapper {
            @include breakpoint('md') {
                display: block;
            }
        }
    }
}

.tabs {
    &__nav {
        display: flex;
    }

    &__nav-item {
        cursor: pointer;
        user-select: none;
    }

    &__content {
        .tabs__item {
            display: none;

            &_active {
                display: block;
            }
        }
    }
}

.button-scroll-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    display: none;
    border: none;
    background-color: white;
    box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
    border-radius: 30px;
    cursor: pointer;
    padding: 10px;

    @include breakpoint('sm') {
        bottom: 50px;
    }

    @include breakpoint('md') {
        right: 30px;
    }

    @include breakpoint('lg') {
        right: 50px;
        bottom: 80px;
    }

    @include breakpoint('xl') {
        right: 100px;
        bottom: 120px;
    }

    .icon {
        width: 20px;
        height: 20px;
        fill: none;
        --stroke-color: black;
    }

    &:hover {
        box-shadow: 0px 8px 30px 10px rgba(108, 108, 108, 0.15);
    }

    &_visible {
        display: flex;
    }
}

.non-scrollable {
    overflow: hidden;
}

.expansion-panel {
    &__header {
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon {
            margin-left: auto;
            width: 20px;
            height: 20px;
            fill: none;
            --stroke-color: black;
        }
    }

    &__content {
        display: none;
    }

    &_active {
        .expansion-panel__header {
            .icon {
                transform: rotate(-180deg);
                --stroke-color: #75adbc;
            }
        }

        .expansion-panel__content {
            display: block;
        }
    }
}

.tooltip {
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
    min-width: 28ch;
    padding: 6px;

    &::before {
        content: '';
        position: absolute;
    }

    &_arrow-top {
        &::before {
            bottom: 100%;
            left: calc(50% - 6px);
            border: 6px solid transparent;
            border-bottom-color: white;
        }
    }

    &_arrow-right {
        &::before {
            top: calc(50% - 6px);
            left: 100%;
            border: 6px solid transparent;
            border-left-color: white;
        }
    }

    &_arrow-bottom {
        &::before {
            top: 100%;
            left: calc(50% - 6px);
            border: 6px solid transparent;
            border-top-color: white;
        }
    }

    &_arrow-left {
        &::before {
            top: calc(50% - 6px);
            right: 100%;
            border: 6px solid transparent;
            border-right-color: white;
        }
    }
}

.badge {
    display: inline-block;
    padding: 0 8px;
    background-color: #75adbc;
    border-radius: 20px;
    color: white;

    &_primary {
        background-color: #eb154c;
    }
}
