$breakpoints: (
  "sm": (
    min-width: 600px
  ),
  "md": (
    min-width: 960px
  ),
  "lg": (
    min-width: 1264px
  ),
  "xl": (
    min-width: 1904px
  )
);

@mixin breakpoint($breakpoint) {
  @media #{inspect(map-get($breakpoints, $breakpoint))} {
    @content;
  }
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
